import {CartItemQuantity} from '@/ui/cart-item-quantity';
import {debounce} from '@/utils/debounce';
import {fetchConfig} from '@/utils/fetchConfig';
import {getFetchedShopifySectionById} from '@/utils/getFetchedShopifySectionById';
import {updateCartDOM} from '@/utils/updateCartDOM';

type CartItem = {
  quantitySelector: HTMLInputElement | null;
  removeBtn: HTMLButtonElement | null;
  element: HTMLElement;
  allButtons: HTMLButtonElement[] | null;
  changeUrl: string | undefined;
};

export class ProductListing extends HTMLElement {
  changeUrl;

  dom: {
    container: HTMLElement;
    items: CartItem[];
    urlParams: URLSearchParams;
    collectionWithFacets: HTMLElement | null;
  };

  constructor() {
    super();
    this.dom = {
      container: this,
      collectionWithFacets: document.getElementById('collection-with-facets'),
      items: Array.from(this.querySelectorAll<HTMLElement>('[data-element=cart-item]')).map((element) => {
        const numberInput = element.querySelector<HTMLElement>('[data-module="number-input"]');
        return {
          quantitySelector: element.querySelector<HTMLInputElement>('[data-element=quantity-selector]'),
          changeUrl: numberInput?.dataset.changeUrl,
          removeBtn: element.querySelector<HTMLButtonElement>('[data-element=remove-btn]'),
          element,
          allButtons: Array.from(element.querySelectorAll<HTMLButtonElement>('button')),
        };
      }),
      urlParams: new URLSearchParams(window.location.search),
    };

    this.changeUrl = this.dataset.changeUrl;

    this.dom.items.forEach((item) => {
      this.addListenersToItem(item);
    });
  }

  addListenersToItem = (item: CartItem) => {
    item.allButtons?.forEach((button: HTMLButtonElement) => {
      button.addEventListener('click', (event: MouseEvent) => {
        if (button.id === 'increment-button' && button.type === 'submit') {
          event.preventDefault();
        }
      });
    });
    item.quantitySelector?.addEventListener(
      'change',
      debounce((event: Event) => {
        const el = event.target as HTMLInputElement;
        if (parseInt(el.value) > parseInt(el.max)) {
          el.value = el.max;
        }
        if (parseInt(el.value) < 0) {
          el.value = el.min;
        }
        this.updateItem({cartItem: item, quantity: el.value ?? '0'}, el);
      }, 300)
    );
    item.removeBtn?.addEventListener('click', (event) => {
      this.updateItem({cartItem: item, quantity: '0'}, event.target as HTMLInputElement);
    });
  };

  initializeNumberInputs = () => {
    const numberInputs = Array.from(document.querySelectorAll<HTMLElement>('[data-module="number-input"]'));
    numberInputs.forEach((container) => new CartItemQuantity(container));
  };

  addListenersToNewItems = (productListing: HTMLElement) => {
    const newCartItems = Array.from(productListing.querySelectorAll<HTMLElement>('[data-element=cart-item]')).map(
      (element) => {
        const numberInput = element.querySelector<HTMLElement>('[data-module="number-input"]');
        return {
          quantitySelector: element.querySelector<HTMLInputElement>('[data-element=quantity-selector]'),
          removeBtn: element.querySelector<HTMLButtonElement>('[data-element=remove-btn]'),
          changeUrl: numberInput?.dataset.changeUrl,
          element,
          allButtons: Array.from(element.querySelectorAll<HTMLButtonElement>('button')),
        };
      }
    );

    newCartItems.forEach((item) => this.addListenersToItem(item));
  };

  updateItem = async ({cartItem, quantity}: {cartItem: CartItem; quantity: string}, el: HTMLElement) => {
    if (!cartItem.changeUrl) {
      console.info('No change URL provided.');
      return;
    }
    const body = JSON.stringify({
      id: cartItem.element.dataset.id!,
      quantity,
      sections: 'shopping-cart,cart-amount,checkout-buttons',
      sections_url: window.location.pathname,
    });

    cartItem.quantitySelector?.setAttribute('disabled', '');
    cartItem.removeBtn?.setAttribute('disabled', '');
    cartItem.allButtons?.forEach((button: HTMLButtonElement) => {
      button.setAttribute('disabled', '');
    });

    try {
      const fetchResponse = await fetch(`${cartItem.changeUrl}.js`, {...fetchConfig(), body});

      if (!fetchResponse.ok) throw new Error('Error updating item');
      const data = await fetchResponse.json();

      const focusElId = `[data-id=${el.dataset.id}]`;
      updateCartDOM(data, focusElId);

      const search = this.dom.urlParams.toString() || '';
      const sectionId = this.dataset.sectionId;
      const url = `${window.location.origin}${window.location.pathname}?section_id=${sectionId}&${search}`;

      const response = await fetch(url);
      if (!response.ok) throw new Error('Error fetching URL');

      const text = await response.text();

      const productListing = document.querySelector('product-listing') as ProductListing;
      productListing.innerHTML = getFetchedShopifySectionById(text, this.dom.collectionWithFacets?.id ?? '');

      this.initializeNumberInputs();
      this.addListenersToNewItems(productListing);

      if (focusElId) {
        document.querySelector<HTMLElement>(focusElId)?.focus();
      }
    } catch (e) {
      console.error(e);
    }
  };
}
