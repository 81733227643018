import {getFetchedShopifySection} from './getFetchedShopifySection';
import {CartItemQuantity} from '../ui/cart-item-quantity';
import {CartRecommendations} from '../ui/cart-recommendations';

let cart = document.querySelector<HTMLElement>('shopping-cart');
let cartAmount = document.querySelector<HTMLElement>('cart-amount');
let checkoutButton = document.querySelector<HTMLButtonElement>('checkout-buttons');
let cartRecommendations = document.querySelector<HTMLElement>('cart-recommendations');

export const updateDom = (htmlAsString: string, el: HTMLElement) => {
  const prevEl = el.previousElementSibling;
  const nextEl = el.nextElementSibling;
  const parentEl = el.parentElement;
  if (prevEl) {
    el.remove();
    prevEl.insertAdjacentHTML('afterend', getFetchedShopifySection(htmlAsString));
  } else if (nextEl) {
    el.remove();
    nextEl.insertAdjacentHTML('beforebegin', getFetchedShopifySection(htmlAsString));
  } else if (parentEl) {
    parentEl.innerHTML = getFetchedShopifySection(htmlAsString);
  }
};

export const updateCartDOM = (data: {sections: Record<string, string>}, focusElId?: string): void => {
  // If elements doesn't exists, get them
  if (!cart) {
    cart = document.querySelector<HTMLElement>('shopping-cart');
  }
  if (!cartAmount) {
    cartAmount = document.querySelector<HTMLElement>('cart-amount');
  }
  if (!checkoutButton) {
    checkoutButton = document.querySelector<HTMLButtonElement>('checkout-buttons');
  }
  if (!cartRecommendations) {
    cartRecommendations = document.querySelector<HTMLElement>('cart-recommendations');
  }

  // Update known DOM elements
  if (cart && data.sections?.['shopping-cart']) {
    updateDom(data.sections['shopping-cart']!, cart);
    cart = document.querySelector<HTMLElement>('shopping-cart');

    // Re-initialize NumberInput instances
    const initializeCartItemQuantities = () => {
      const cartItemQuantityElements = Array.from(document.querySelectorAll<HTMLElement>('[data-module="number-input"]'));
      cartItemQuantityElements.forEach((container) => new CartItemQuantity(container));
    };

    initializeCartItemQuantities();
  }
  if (cartAmount && data.sections?.['cart-amount']) {
    updateDom(data.sections['cart-amount'], cartAmount);
    cartAmount = document.querySelector<HTMLElement>('cart-amount');
  }
  if (checkoutButton && data.sections?.['checkout-buttons']) {
    updateDom(data.sections['checkout-buttons'], checkoutButton);
    checkoutButton = document.querySelector<HTMLButtonElement>('checkout-buttons');
  }
  if (cartRecommendations && data.sections?.['cart-recommendations']) {
    updateDom(data.sections['cart-recommendations'], cartRecommendations);
    cartRecommendations = document.querySelector<HTMLElement>('cart-recommendations');

    const initializeCartRecommendations = () => {
      const cartRecommendationElements = Array.from(
        document.querySelectorAll<HTMLElement>('[data-module="cart-recommendations"]')
      );
      cartRecommendationElements.forEach(() => new CartRecommendations());
    };

    initializeCartRecommendations();
  }

  if (focusElId) {
    document.querySelector<HTMLElement>(focusElId)?.focus();
  }
};
