import {spinner} from '@/js-html/spinner';
import {dispatchCustomEvent} from '@/utils/dispatchCustomEvent';
import {updateCartDOM} from '@/utils/updateCartDOM';

export class ProductForm {
  container;
  form;
  submitBtn;

  constructor(container: HTMLElement) {
    this.container = container;
    this.form = container.querySelector<HTMLFormElement>('form')!;
    this.submitBtn = this.form.querySelector<HTMLButtonElement>('button[type=submit]')!;

    this.form.addEventListener('submit', this.onSubmitHandler);
  }

  onSubmitHandler = (event: SubmitEvent) => {
    event.preventDefault();
    this.addToCart(this.submitBtn);
  };

  addToCart = async (element: HTMLElement | null) => {
    const config: RequestInit = {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/javascript',
      },
    };

    // Prepare form data
    const formData = new FormData(this.form);
    formData.append('sections', 'shopping-cart,cart-amount,checkout-buttons,cart-recommendations');
    formData.append('sections_url', window.location.pathname);
    config.body = formData;

    // Add spinner + disabled
    const buttonText = this.submitBtn?.innerHTML;
    this.submitBtn.innerHTML = spinner();
    this.submitBtn.setAttribute('disabled', '');

    // Fetch data and update DOM
    const response = await fetch(this.container.dataset.addToCartUrl!, config);
    const data = await response.json();
    updateCartDOM(data);

    // Remove spinner + disabled
    this.submitBtn.innerHTML = buttonText;
    this.submitBtn.removeAttribute('disabled');

    dispatchCustomEvent('cart:toggle', {
      detail: {element},
    });
  };
}
