import {debounce} from '@/utils/debounce';
import {fetchConfig} from '@/utils/fetchConfig';
import {updateCartDOM} from '@/utils/updateCartDOM';

type CartItem = {
  quantitySelector: HTMLInputElement | null;
  removeBtn: HTMLButtonElement | null;
  element: HTMLElement;
  allButtons: HTMLButtonElement[] | null;
};

export class ShoppingCart extends HTMLElement {
  changeUrl;

  dom: {
    container: HTMLElement;
    items: CartItem[];
  };

  constructor() {
    super();
    this.dom = {
      container: this,
      items: Array.from(this.querySelectorAll<HTMLElement>('[data-element=cart-item]')).map((element) => {
        return {
          quantitySelector: element.querySelector<HTMLInputElement>('[data-element=quantity-selector]'),
          removeBtn: element.querySelector<HTMLButtonElement>('[data-element=remove-btn]'),
          element,
          allButtons: Array.from(element.querySelectorAll<HTMLButtonElement>('button')),
        };
      }),
    };

    this.changeUrl = this.dataset.changeUrl;

    this.dom.items.forEach((item) => {
      this.addListenersToItem(item);
    });
  }

  addListenersToItem = (item: CartItem) => {
    item.allButtons?.forEach((button: HTMLButtonElement) => {
      button.addEventListener('click', (event: MouseEvent) => {
        if (button.id === 'increment-button' && button.type === 'submit') {
          event.preventDefault();
        }
      });
    });
    item.quantitySelector?.addEventListener(
      'change',
      debounce((event: Event) => {
        const el = event.target as HTMLInputElement;
        if (parseInt(el.value) > parseInt(el.max)) {
          el.value = el.max;
        }
        if (parseInt(el.value) < 0) {
          el.value = el.min;
        }
        this.updateItem({cartItem: item, quantity: el.value ?? '0'}, el);
      }, 300)
    );
    item.removeBtn?.addEventListener('click', (event) => {
      this.updateItem({cartItem: item, quantity: '0'}, event.target as HTMLInputElement);
    });
  };

  updateItem = async ({cartItem, quantity}: {cartItem: CartItem; quantity: string}, el: HTMLElement) => {
    const focusElId = parseInt(quantity) > 0 ? `[data-id=${el.dataset.id}]` : '#continue-shopping-btn';
    const body = JSON.stringify({
      id: cartItem.element.dataset.id!,
      quantity,
      sections: 'shopping-cart,cart-amount,checkout-buttons,cart-recommendations',
      sections_url: window.location.pathname,
    });

    cartItem.quantitySelector?.setAttribute('disabled', '');
    cartItem?.removeBtn?.setAttribute('disabled', '');
    cartItem.allButtons?.forEach((button: HTMLButtonElement) => {
      button.setAttribute('disabled', '');
    });

    const response = await fetch(this.changeUrl!, {...fetchConfig(), body});
    const data = await response.json();

    updateCartDOM(data, focusElId);
  };
}
